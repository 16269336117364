import React, { FC } from 'react'

const FourZeroFourPage: FC = __ => (
  <div>
    <h1>404</h1>
    <p>Not found</p>
  </div>
)

export default FourZeroFourPage
